






















































































































import Vue, { VueConstructor } from 'vue';
import CustomerMessageService from '@/services/customers/CustomerMessageService';
import MessageStatus from '@/components/messages/MessageStatus.vue';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import { MetaInfo } from 'vue-meta';

class CustomerMessageIndexDto {
  id!: string;
  sender!: string;
  senderId!: string;
  isSent!: boolean;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { MessageStatus, FormatedTimestamp, DataTable, DeleteConfirmationModal },
  mixins: [FormaterMixin],
  data() {
    return {
      Messages: [] as CustomerMessageIndexDto[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'CreatedAt', asc: false },
      currentPage: 1,
      loading: false,
      showDeleteModal: false,
      deleteMessageId: '',
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.guest.GuestMessageIndex.meta.title').toString(),
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.guest.GuestMessageIndex.breadcrumb.last' },
      ]);
    },

    parseDate(dateString: string): Date | null {
      return this.parseIsoDateTimeTz(dateString);
    },

    loadData(): void {
      this.loading = true;
      CustomerMessageService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.Messages = res.value.messages.items;
        this.pages = res.value.messages.totalPages;
        this.setBreadCrumb();
      });
    },

    deleteMessage(messageId: string): void {
      this.showDeleteModal = true;
      this.deleteMessageId = messageId;
    },

    internalOnDelete(): void {
      CustomerMessageService.delete(this.deleteMessageId).then(() => {
        this.loadData();
        this.showDeleteModal = false;
      });
    },

    canAnswer(message: CustomerMessageIndexDto): boolean {
      return message.senderId != null && !message.isSent;
    },

    closeDeleteModal(): void {
      this.showDeleteModal = false;
    },
  },
});
